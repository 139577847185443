import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import BlogGridPost from 'components/blogGridPost'


export default function SinglePostNextPrev ({
    postData,
}) {

    const {nodes: posts } = useStaticQuery(graphql`
        query {
            siteFields {
                posts (
                    first: 50
                ) {
                    nodes {
                        date
                        title
                        content
                        databaseId
                        categories {
                            nodes {
                                name
                            }
                        }
                        featuredImage {
                            mediaItemUrl
                        }
                        link
                        postFields {
                            featuredPost
                        }
                    }
                }
            }
        }
    `).siteFields.posts

    const currentPostIndex = posts ? posts.findIndex(_post => _post.databaseId === postData.page.databaseId) : null

    const nextPost = posts && currentPostIndex > 0 ? posts[ currentPostIndex - 1 ] : null
    const prevPost = posts && currentPostIndex < posts.length ? posts[ currentPostIndex + 1 ] : null

    return (
        <>
            <div className="single-post-nextprev">
                <ul>
                    <li className='prev-post'>
                        { prevPost &&
                            <BlogGridPost post={ prevPost } fullLink={ true } button={ true } />
                        }
                    </li>
                    <li>
                        { nextPost &&
                            <BlogGridPost post={ nextPost } fullLink={ true } button={ true } />
                        }
                    </li>
                </ul>
            </div>
        </>
    )
}

import React, { useState, useContext, useEffect } from 'react'

export default () => {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : ''
    const [linkTitle, setLinkTitle] = useState('Copy Link')

    const handleCopy = () => {
        const temp = document.createElement('input')
        document.body.appendChild(temp)
        temp.value = currentUrl
        temp.select()
        document.execCommand('copy')
        document.body.removeChild(temp)

        setLinkTitle('Copied Link!')

        setTimeout(() => {
            setLinkTitle('Copy Link')
        }, 1000)
    }

    return (
        <div className="blog-socials">
            <div className="social-container">
                <section className="social-links">
                    <a 
                        href={`https://www.facebook.com/sharer/sharer.php?u=${ currentUrl }`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Facebook
                    </a>
                    <a 
                        href={`https://twitter.com/intent/tweet?text=&amp;url=${ currentUrl }`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Twitter
                    </a>
                    <a 
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${ currentUrl}&amp;`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        LinkedIn
                    </a>
                    <a 
                        onClick={handleCopy}
                        className='change-copy'
                    >
                        {linkTitle}
                    </a>
                </section>
                <section className="share">
                    <p>Share</p>
                </section>
            </div>
        </div>
    )
}

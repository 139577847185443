import React from 'react'

export default ({ post }) => {
	const {
        title,
		postFields,
        categories,
	} = post

    return (
        <div className="blogSingle-intro">
            <div className="container">
                {categories?.nodes[0] &&
                    <div className='specialty-types'>
                        {categories.nodes.map((type, index) => {
                            return (
                                <h4 key={index} className='specialty' dangerouslySetInnerHTML={{__html: type.name.split('&amp;').join('&') }} />
                            )
                        })}
                    </div>
                }
                { postFields.postHeading ?
                    <div className="blogSingle-intro-heading" dangerouslySetInnerHTML={{ __html: postFields.postHeading }} />
                :
                    <div className="blogSingle-intro-heading">
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                }
            </div>
        </div>
    )
}

import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"
import BlogSingleHero from "components/blogSingleHero"
import BlogSinglePrevNext from "components/blogSinglePrevNext"
import BlogSingleIntro from "components/blogSingleIntro"
import BlogSingleSocials from "components/blogSingleSocials"

export default ({ data }) => {

    const {
        post: {
            title,
            excerpt,
            featuredImage,
            postFields,
        },
        page: {
            databaseId,
            slug,
            flexibleContent,
        },
    } = data.siteFields

	const {
		pageTitle,
		meta
	} = flexibleContent

    return (
		<>
			<SEO 
				title={pageTitle ? pageTitle : title} 
				description={(meta && meta.description) ? meta.description : excerpt} 
				metaTitle={(meta && meta.title) ? meta.title : undefined}
				image={featuredImage ? featuredImage.mediaItemUrl : ''}
			/>
            <BlogSingleHero image={ postFields.hero ? postFields.hero.mediaItemUrl : featuredImage ? featuredImage.mediaItemUrl : '' } />
            <div className={ "blogSingle-cont blogSingle-cont-" + postFields.backgroundColour }>
                <BlogSingleIntro post={ data.siteFields.post } />
                <FlexibleContent
                    id={databaseId}
                    content={flexibleContent}
                    slug={slug}
                />
                <BlogSingleSocials />
                <BlogSinglePrevNext postData={data.siteFields} />
            </div>
		</>
    )
}

export const query = graphql`
	query($databaseId: ID!) {
		siteFields {
			post(id: $databaseId, idType: DATABASE_ID) {
                id
                title
                slug
                date
                link
                content(format: RENDERED)
                featuredImage {
                    mediaItemUrl
                }
                categories {
                    nodes {
                        name
                    }
                }
                postFields {
                    hero {
                        mediaItemUrl
                    }
                    backgroundColour
                    postHeading
                }
            }
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                slug
                date
                content(format: RENDERED)
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
		}
	}
`
